<template>
  <div>
    <v-container class="mt-10 pt-10">
      <!-- <Sidebar /> -->
      <HeaderPage />
      <BackComponent :title_name="$t('message.title-add-address')" />

      <div>
        <FormAddress @on-submit="createItem" />
      </div>
    </v-container>
    <Sidebar />
  </div>
</template>

<script>
import Sidebar from "../components/developmentv2/Sidebar.vue";
import HeaderPage from '../components/developmentv2/HeaderPage.vue'
import Back from "../components/Back.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import FormAddress from "../components/FormAddress.vue";

export default {
  name: "AddAddress",
  components: { Sidebar, BackComponent: Back, Swal, FormAddress, HeaderPage },
  data() {
    return {};
  },
  methods: {
    createItem(address) {
      this.$store
        .dispatch("address_module/addFormAddress", address)
        .then((res) => {
          if (res.status == 200) {
            this.$router.go(-1);
          }
        });
    },
  },
  computed: {},
};
</script>

<style></style>
