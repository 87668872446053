<template>
  <div>
    <v-col class="pt-0 pb-2" cols="12">
      <v-form v-model="isValid" @submit.prevent="submitFormAddress">
        <v-row style="margin-top: 25px">
          <v-col class="pb-0 pt-0" cols="12" md="2" xs="2">
            <span class="label-class required">{{
              $t("message.address-name")
            }}</span>
            <p style="font-size:70%;">※お届け先の個人名または、法人名と個人名を入力してます</p>
          </v-col>
          <v-col cols="12" md="10" xs="2" class="pt-0 input-class ">
            <v-text-field
              v-model="formAddress.name"
              :error-messages="nameErrors"
              @input="$v.formAddress.name.$touch()"
              @blur="$v.formAddress.name.$touch()"
              required
              outlined
            />
          </v-col>
          <v-col class="pb-0 pt-0" cols="12" md="2" xs="2">
            <span class="label-class required">
              {{ $t("message.label-postal") }}
            </span>
          </v-col>
          <v-col cols="12" md="10" xs="2" class="pt-0 input-class">
            <v-row align="center">
              <v-col cols="8">
                <v-text-field
                  v-model="formAddress.postal"
                  :error-messages="postalErrors"
                  @input="$v.formAddress.postal.$touch()"
                  @blur="$v.formAddress.postal.$touch()"
                  required
                  outlined
                />
              </v-col>
              <v-col class="mb-5">
                <v-btn
                depressed
                :disabled="isPostal"
                outlined
                  class="ml-2 title-button"
                  @click.prevent="getAddress()"
                >
                  <v-progress-circular
                  v-if="loadPostal"
                    indeterminate
                    color="#ff0090"
                    size="11"
                    left
                  ></v-progress-circular>
                  <span v-else style="font-size: 10px">{{
              $t("message.search")
            }}</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="pb-0 pt-0" cols="12" md="2" xs="2">
            <span class="label-class required">{{
              $t("message.label-prefecture")
            }}</span>
          </v-col>
          <v-col cols="12" md="10" xs="2" class="pt-0 input-class">
            <v-text-field
              v-model="formAddress.prefecture"
              :error-messages="prefectureErrors"
              @input="$v.formAddress.prefecture.$touch()"
              @blur="$v.formAddress.prefecture.$touch()"
              required
              outlined
            />
          </v-col>
          <v-col class="pb-0 pt-0" cols="12" md="2" xs="2">
            <span class="label-class required">{{
              $t("message.label-city")
            }}</span>
          </v-col>
          <v-col cols="12" md="10" xs="2" class="pt-0 input-class">
            <v-text-field
              v-model="formAddress.city"
              :error-messages="cityErrors"
              @input="$v.formAddress.city.$touch()"
              @blur="$v.formAddress.city.$touch()"
              required
              outlined
            />
          </v-col>
          <v-col class="pb-0 pt-0" cols="12" md="2" xs="2">
            <span class="label-class">{{ $t("message.label-district") }}</span>
          </v-col>
          <v-col cols="12" md="10" xs="2" class="pt-0 input-class">
            <v-text-field v-model="formAddress.district" outlined />
          </v-col>
          <v-col class="pb-0 pt-0" cols="12" md="2" xs="2">
            <span class="label-class">{{$t("message.label-address-ward")}}</span>
          </v-col>
          <v-col cols="12" md="10" xs="2" class="pt-0 input-class">
            <v-text-field
              outlined
              v-model="formAddress.ward"
            ></v-text-field>
          </v-col>
          <v-col class="pb-0 pt-0" cols="12" md="2" xs="2">
            <span class="label-class">{{ $t("message.label-address-street_address") }}</span>
          </v-col>
          <v-col cols="12" md="10" xs="2" class="pt-0 input-class">
            <v-text-field
            v-model="formAddress.street_address"
            outlined />
          </v-col>
          <v-col class="pb-0 pt-0" cols="12" md="2" xs="2">
            <span class="label-class">{{ $t("message.label-building") }}</span>
          </v-col>
          <v-col cols="12" md="10" xs="2" class="pt-0 input-class">
            <v-text-field v-model="formAddress.building" outlined />
          </v-col>
        </v-row>
        <div class="d-flex flex-row align-end justify-end" style="margin-bottom: 8rem">
          <v-btn
            type="submit"
            rounded
            color="#ff0090"
            depressed
            style="color: white"
            :disabled="!isValid"
            @click.prevent="submitFormAddress"
          >
            {{ $t("message.btn-submit") }}
          </v-btn>
        </div>
      </v-form>
    </v-col>
  </div>
</template>

<script>
import Sidebar from "../components/Sidebar.vue";
import Back from "../components/Back.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";

import { mdiArrowLeft, mdiMagnify } from "@mdi/js";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "AddAddress",
  components: { Sidebar, BackComponent: Back, Swal },
  props: {
    itemDetail: Object
  },
  mixins: [validationMixin],
  validations: {
    formAddress: {
      name: { required },
      postal: { required },
      prefecture: { required },
      // ward: { required },
      // building: { required },
      // district: { required },
      // street_address: { required },
      city: { required },
    },
  },
  data() {
    return {
      iconArrowLeft: mdiArrowLeft,
      iconSearch: mdiMagnify,
      isPostal: false,
      isValid: true,
      loadPostal: false,
      dataErr: "",
      errMsg: "",
      formAddress: {
        name: "",
        postal: "",
        prefecture: "",
        // address: "",
        building: "",
        district: "",
        ward: "",
        street_address: "",
        city: "",
      },
      errorValues: {},
      errorFields: ["errMsg"],
    };
  },
  created(){
    this.getOneItem()
  },
  methods: {
    getOneItem() {
      let id = this.$route.params.id;

      if (id) {
        this.isEdited = id;
      } else {
        this.isEdited = -1;
      }
      if (this.isEdited > -1) {
        this.formAddress = Object.assign(this.itemDetail, this.formAddress);
      }
    },
    submitFormAddress() {
      this.$v.$touch();

      let refacPostcode = this.formAddress.postal
      refacPostcode = refacPostcode.replace("-","")
      this.formAddress.postal = refacPostcode

      this.$emit("on-submit", this.formAddress);
    },

    async getAddress() {
      this.loadPostal = true
      let postalcode = this.formAddress.postal;
      postalcode = postalcode.replace("-","");
      let resp = await this.$store.dispatch(
        "address_module/getAddressByPostal",
        { postal: postalcode }
      );
      this.loadPostal = false
      if (resp.data.length > 0) {
        let respaddr = resp.data[0];
        this.formAddress.prefecture = respaddr.pref;
        this.formAddress.city = respaddr.city;
        this.formAddress.district = respaddr.town;
      } else {
        let message;
        if (typeof resp.data.message === "undefined")
          message = this.$t("message.err-msg-postal-code-notfound");
        else message = this.$t("message.err-msg-postal-code-api-error");

        this.formAddress.prefecture = "";
        this.formAddress.city = "";
        this.formAddress.district = "";
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: false,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "warning",
          title: message,
        });
      }
    },
  },
  computed: {
    reqErrorMessage() {
      return this.$store.state.address_module.reqErrMsg;
    },
    nameErrors() {
      let errors = [];
      if (!this.$v.formAddress.name.$dirty) return errors;
      !this.$v.formAddress.name.required &&
        errors.push(this.$t("message.err-msg-name-register"));
      return errors;
    },
    postalErrors() {
      let errors = [];
      this.isPostal = false
      if (!this.$v.formAddress.postal.$dirty) return errors;
      !this.$v.formAddress.postal.required &&
        errors.push(this.$t("message.err-msg-postalcode-required"));
      if (this.formAddress.postal == 0) {
        errors.push(this.$t("message.err-msg-postal-zero"));
      }
        if (errors.length > 0) {
          this.isPostal = true
        }

      return errors;
    },
    prefectureErrors() {
      let errors = [];
      if (!this.$v.formAddress.prefecture.$dirty) return errors;
      !this.$v.formAddress.prefecture.required &&
        errors.push(this.$t("message.err-msg-prefecture-required"));
      return errors;
    },
    // wardErrors() {
    //   let errors = [];
    //   if (!this.$v.formAddress.ward.$dirty) return errors;
    //   !this.$v.formAddress.ward.required &&
    //     errors.push(this.$t("message.err-msg-ward"));
    //   return errors;
    // },
    // streetAddressErrors() {
    //   let errors = [];
    //   if (!this.$v.formAddress.street_address.$dirty) return errors;
    //   !this.$v.formAddress.street_address.required &&
    //     errors.push(this.$t("message.err-msg-street_address"));
    //   return errors;
    // },
    // buildingErrors() {
    //   let errors = [];
    //   if (!this.$v.formAddress.building.$dirty) return errors;
    //   !this.$v.formAddress.building.required &&
    //     errors.push(this.$t("message.err-msg-building-required"));
    //   return errors;
    // },
    // districtErrors() {
    //   let errors = [];
    //   if (!this.$v.formAddress.district.$dirty) return errors;
    //   !this.$v.formAddress.district.required &&
    //     errors.push(this.$t("message.err-msg-district-required"));
    //   return errors;
    // },
    cityErrors() {
      let errors = [];
      if (!this.$v.formAddress.city.$dirty) return errors;
      !this.$v.formAddress.city.required &&
        errors.push(this.$t("message.err-msg-city-required"));
      return errors;
    },
  },
};
</script>

<style scoped>
.required:after {
  content: " *";
  color: red;
}
</style>
